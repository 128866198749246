import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html'
})
export class ContactFormComponent implements OnInit {
  protected contactFormGroup: FormGroup;
  showErrors: boolean = false;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.contactFormGroup = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required],
      recaptcha: ['', Validators.required]
    });
  }

  submitForm() {
    if (this.contactFormGroup.invalid) {
      console.log('invalid: ', this.contactFormGroup.invalid);
      this.showErrors = true;
      return;
    }

    console.log(this.contactFormGroup.value);
  }
}

