import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {APP_BASE_HREF} from "@angular/common";
import {PagesModule} from "./pages/pages.module";
import {RouterModule} from "@angular/router";
import {FooterComponent} from "./componentens/footer.component";
import {ResponsiveImageComponent} from "./componentens/responsive-image.component";
import {NgxCaptchaModule} from "ngx-captcha";
import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PagesModule,
    RouterModule.forRoot([]),
    ReactiveFormsModule,
    NgxCaptchaModule
  ],
  providers: [{provide: APP_BASE_HREF, useValue: '/'}],
  bootstrap: [AppComponent]
})
export class AppModule {
}
